import { useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import InsuranceQuote from '../../components/loan/insuranceQuote/InsuranceQuote';
import { LoanSupport } from '../../components/loan/support/Support';
import { WaitingForTasks } from '../../components/loan/waitingForTasks/WaitingForTasks';
import withLoan from '../../components/loan/withLoan';
import { PATHS, homeLoanFundedPaths, homeLoanInProcessPaths, linkTo } from '../../components/routes/paths';
import { useWaitingForTasksSetup } from '../../features/app/useWaitingForTasks';
import { isApplicationLoan, isFundedLoan, isLoanReady, isLoanSameDayIneligible } from '../../features/loans/helpers';
import { AlpMilestone, AlpLoanType, type Loan } from '../../features/loans/types';
import useEmbed from '../../hooks/useEmbed';
import { useLoanFirstVisitAt } from '../../hooks/useLoanFirstVisitAt';
import { useLoanTasksReadyAt } from '../../hooks/useLoanTasksReadyAt';
import { LocationState } from '../../interfaces/ILocationState';
import { log } from '../../utils/logger';
import LoanDetails from './Details';
import LoanDocuments from './Documents';
import LoanNav from './LoanNav';
import Overview from './Overview';
import LoanServicing from './Servicing';
import LoanTasklist from './Tasklist';
import { HelpfulLinks } from '../../components/loan/helpfulLink/HelpfulLinks';
import { useShowHelpfulLinks } from '../../features/managedContent/managedContentSlice';
import { RateAppAd2 } from '../../components/rateAppAd/RateAppAd';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { useMobileAppMode } from '../../components/embedMode/EmbedMode';
import { LeftContentRightRail } from '../../components/layout/Layout';
import RateAlert from '../../components/rateAlert/RateAlert';
import { formatAddressObject } from '../../utils/addressFormatter';

const RateAppRightRailAd = () => {
  const mobileApp = useMobileAppMode();
  const enabled = useFeatureEnabled('rate-app-ad');

  if (!enabled || mobileApp) {
    return <InsuranceQuote location='right-rail' />;
  }

  return (
    <div className='flex justify-center lg:justify-start mt-8'>
      <RateAppAd2 location='rightRail' />
    </div>
  );
};

const HomeLoanComponent = ({ loan }: { loan: Loan }) => {
  const isFunded = isFundedLoan(loan);
  const embed = useEmbed();
  const showHelpfulLinks = useShowHelpfulLinks() && loan['alp-loan-type'] !== AlpLoanType.CES;
  const drawerContainerId = 'helpful-links-container';
  const location = useLocation<LocationState>();
  const showRateAlert = useFeatureEnabled('rate-alert') && isFunded;
  const alpHELOCMilestone = loan['alp-heloc-loan']?.milestone;
  const showTasksTab = !isFunded && alpHELOCMilestone !== AlpMilestone.DENIED;
  const showDetailsTab = !isFunded && alpHELOCMilestone !== AlpMilestone.DENIED;

  useLoanTasksReadyAt(loan);

  const RightRail = (
    <>
      {!showHelpfulLinks && <LoanSupport loan={loan} />}
      {showHelpfulLinks && <HelpfulLinks loan={loan} containerId={drawerContainerId} />}
      {showRateAlert && <RateAlert loan={loan} />}
      {!embed && (
        <Switch>
          <Route path={[PATHS.homeLoanTasks, PATHS.homeLoanDetails]} exact={true}>
            <RateAppRightRailAd />
          </Route>
          <Route path='*' exact={true}>
            <InsuranceQuote location='right-rail' />
          </Route>
        </Switch>
      )}
    </>
  );

  const LoanInfoHero = ({ loan }: { loan: Loan }) => {
    let loanLabel = 'Mortgage';
    if (loan['alp-loan-type'] === AlpLoanType.HELOC) {
      loanLabel = 'HELOC loan';
    } else if (loan['alp-loan-type'] === AlpLoanType.CES) {
      loanLabel = 'CES loan';
    }
    return (
      <div className='rounded-xl mb-8 px-5 py-3' style={{ backgroundColor: '#f5f1e9' }}>
        <div className='flex flex-col lg:flex-row text-base lg:text-xl'>
          <p>{loanLabel} #{loan['loan-number']}</p>
          <p className='hidden lg:block mx-2 text-inactive-25'>|</p>
          <p className='font-bold'>{formatAddressObject(loan.property)}</p>
        </div>
      </div>
    );
  };

  return (
    <>
    <LoanInfoHero loan={loan} />
    <LeftContentRightRail
      subnav={<LoanNav loan={loan} />}
      rightRail={RightRail}
      classNames={{ 'app-page-with-nav': 'flex-grow', 'app-page-contents': 'lg:flex-grow' }}
      ids={{ 'right-rail-contents-wrapper': drawerContainerId }}
    >
      <Switch>
        {!isFunded && (
          <Route path={PATHS.homeLoanOverview} exact={true}>
            <Overview loan={loan} />
          </Route>
        )}
        {showTasksTab && (
          <Route path={PATHS.homeLoanTasks} exact={true}>
            <LoanTasklist loan={loan} />
          </Route>
        )}
        {showDetailsTab && (
          <Route path={PATHS.homeLoanDetails} exact={true}>
            <LoanDetails loan={loan} />
          </Route>
        )}
        {isFunded && (
          <Route path={PATHS.homeLoanServicing} exact={true}>
            <LoanServicing loan={loan} />
          </Route>
        )}
        <Route path={PATHS.homeLoanDocuments} exact={true}>
          <LoanDocuments loan={loan} />
        </Route>
        <Redirect
          to={{
            pathname: location.pathname,
            state: { notFoundError: true },
          }}
        />
      </Switch>
    </LeftContentRightRail>
    </>
  );
};

const DELAY_AFTER_WAITING_ANIMATION = 150; // in ms

const WithLoanInitialization = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const { waiting: showWaitingModal } = useWaitingForTasksSetup(loan);
  const [renderWaitingModal, setRenderWaitingModal] = useState<boolean>(showWaitingModal);

  useLoanFirstVisitAt(loanGuid);

  return (
    <>
      {renderWaitingModal && (
        <WaitingForTasks
          show={showWaitingModal}
          onClosed={() => setTimeout(() => setRenderWaitingModal(false), DELAY_AFTER_WAITING_ANIMATION)}
        />
      )}
      {!showWaitingModal && <HomeLoanComponent loan={loan} />}
    </>
  );
};

const WithRedirects = ({ loan }: { loan: Loan }) => {
  const {
    'loan-guid': loanGuid,
    'loan-milestone': loanMilestone,
    'digital-mortgage?': digitalMortage,
    'application-status': applicationStatus,
  } = loan;
  const homeLoanMatch = useRouteMatch({ path: PATHS.homeLoan, exact: true });
  const homeLoanOverviewMatch = useRouteMatch({ path: PATHS.homeLoanOverview, exact: true });
  const homeLoanFundedPathsMatch = useRouteMatch({ path: homeLoanFundedPaths, exact: true });
  const homeLoanInProcessPathsMatch = useRouteMatch({ path: homeLoanInProcessPaths, exact: true });
  const applicationLoan = isApplicationLoan(loan);
  const isFunded = isFundedLoan(loan);

  // Servicing route is default funded landing page
  if (isFunded && (homeLoanMatch || (homeLoanInProcessPathsMatch && !homeLoanFundedPathsMatch))) {
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoanServicing, { guid: loan['loan-guid'] }),
        }}
      />
    );
  }

  // Funded
  if (isFunded) {
    return <HomeLoanComponent loan={loan} />;
  }

  // Application loans should not be accessible
  if (applicationLoan) {
    const redirectReason = 'Application loan';
    log({
      loanGuid,
      message: `${redirectReason}, redirecting back to the loan center page ${JSON.stringify({
        digitalMortage,
        applicationStatus,
      })}`,
    });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.loanCenter),
        }}
      />
    );
  }

  // Overview route is default tasklist landing page
  if (homeLoanMatch) {
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoanOverview, { guid: loan['loan-guid'] }),
        }}
      />
    );
  }

  // Shouldn't happen but if user attempts to visit a page that isn't ready, redirect back to the overview page
  if (!homeLoanOverviewMatch && (!isLoanReady(loan) || isLoanSameDayIneligible(loan))) {
    log({
      loanGuid,
      message: `Tasklist loan is not ready yet, redirecting back to the loan overview page ${JSON.stringify({
        digitalMortage,
        applicationStatus,
        loanMilestone,
        loanReady: isLoanReady(loan),
        loanSameDayIneligible: isLoanSameDayIneligible(loan),
      })}`,
    });
    return (
      <Redirect
        to={{
          pathname: linkTo(PATHS.homeLoanOverview, { guid: loanGuid }),
        }}
      />
    );
  }

  return <WithLoanInitialization loan={loan} />;
};

export default withTransaction('Loan', 'page-load')(withLoan(WithRedirects));
