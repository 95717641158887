import { AlpHELOCLoanCardPresentational } from '../../../components/loan/loanCard/AlpHELOCLoanCard';
import { HomeLoanCardPresentational } from '../../../components/loan/loanCard/HomeLoanCard';
import { DashboardAlpHELOCLoanCardPresentational } from '../../../dashboard/DashboardAlpHELOCLoanCard';
import { DashboardHELOCLoanCardPresentational } from '../../../dashboard/DashboardHELOCLoanCard';
import { DashboardHomeLoanCardPresentational } from '../../../dashboard/DashboardHomeLoanCard';
import { DashboardPersonalLoanCardPresentational } from '../../../dashboard/DashboardPersonalLoanCard';
import { AlpMilestone } from '../../../features/loans/types';
import { getAlpHELOCLoan, getFundedAlpHELOCLoan } from '../../../mocks/alpHelocLoanInprocess';
import helocLoanFunded from '../../../mocks/helocLoanFunded';
import loanFunded from '../../../mocks/loanFunded';
import loanInprocess from '../../../mocks/loanInprocess';
import { personalLoanInprocess } from '../../../mocks/personalLoanInprocess';

const HomeLoanCard1 = () => {
  const loan = loanInprocess;
  return <HomeLoanCardPresentational loan={loan} />;
};

const HomeLoanCard2 = () => {
  const loan = loanFunded;
  return <HomeLoanCardPresentational loan={loan} />;
};

const VaHomeLoanCard1 = () => {
  const loan = { ...loanInprocess };
  loan['va?'] = true;
  return <HomeLoanCardPresentational loan={loan} />;
};

const HELOCLoanCard1 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPLICATION);
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const HELOCLoanCard2 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.PREAPPROVED);
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const HELOCLoanCard3 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPROVED);
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const HELOCLoanCard4 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.CLOSING);
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const HELOCLoanCard5 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.DENIED);
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const HELOCLoanCard6 = () => {
  const loan = getFundedAlpHELOCLoan();
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const HELOCLoanCard7 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.CLOSED);
  return (
    <AlpHELOCLoanCardPresentational loan={loan} />
  );
};

const DashboardLoanCard = () => {
  const loan = loanInprocess;
  return <DashboardHomeLoanCardPresentational loan={loan} />;
};

const DashboardHELOCLoanCard = () => {
  const loan = helocLoanFunded;
  return <DashboardHELOCLoanCardPresentational loan={loan} />;
};

const DashboardALPHELOCLoanCard1 = () => {
  const loan = getAlpHELOCLoan(AlpMilestone.APPLICATION);
  return <DashboardAlpHELOCLoanCardPresentational loan={loan} />;
};

const DashboardPersonalLoanCard = () => {
  const loan = personalLoanInprocess;
  return <DashboardPersonalLoanCardPresentational loan={loan} />;
};

const LoanCardsStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Mock home loan cards</div>
      <div className='mb-2' />
      <HomeLoanCard1 />
      <div className='mb-2' />
      <HomeLoanCard2 />
      <div className='mb-2' />
      <VaHomeLoanCard1 />
      <div className='header-medium mb-8'>Mock falcon loan cards</div>
      <div className='mb-2' />
      <HELOCLoanCard1 />
      <div className='mb-2' />
      <HELOCLoanCard2 />
      <div className='mb-2' />
      <HELOCLoanCard3 />
      <div className='mb-2' />
      <HELOCLoanCard4 />
      <div className='mb-2' />
      <HELOCLoanCard7 />
      <div className='mb-2' />
      <HELOCLoanCard5 />
      <div className='mb-2' />
      <HELOCLoanCard6 />
      <div className='header-medium mb-8'>Mock Dashboard loan cards</div>
      <div className='mb-2' />
      <DashboardLoanCard />
      <div className='mb-2' />
      <DashboardHELOCLoanCard />
      <div className='mb-2' />
      <DashboardALPHELOCLoanCard1 />
      <div className='mb-2' />
      <DashboardPersonalLoanCard />
    </>
  );
};

export default LoanCardsStyleguide;
