import { SingleColumnContent } from '../components/layout/Layout';
import { useUser } from '../features/user/userSlice';
import { useIsLoadingLoans } from '../hooks/useIsLoadingLoans';
import SavedHomesComponent from '../pages/homeSearch/SavedHomes';
import { LoanCardSkeletonLoader, RateAppCallout } from '../pages/loans/Loans';
import DashboardRateAlert from './DashboardRateAlert';
import DashboardLoansList from './DashboardLoansList';

const DashboardContent = () => {
  const user = useUser();
  return (
    <div>
      <h1 className='mb-4 mt-2 text-marketing-xs md:text-marketing-md'>Welcome{user?.name?.first && <span className='OneLinkNoTx'>, {user.name.first}</span>}</h1>
      <DashboardLoansList />
      <DashboardRateAlert className='mt-12' />
      <SavedHomesComponent className='mt-12' />
      <RateAppCallout />
    </div>
  );
};

const Dashboard = () => {
  const loadingLoans = useIsLoadingLoans();
  return (
    <SingleColumnContent>
      {loadingLoans ? (
        <LoanCardSkeletonLoader />
      ) : (
        <DashboardContent />
      )}
    </SingleColumnContent>
  );
};

export default Dashboard;
