import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { selectProcessingHELOCLoanIds, selectFundedHELOCLoanIds, selectHELOCLoans } from '../features/helocLoans/helocLoansSlice';
import {
  selectProcessingLoanGuids,
  selectLoansByGuidEntity,
  selectProcessingCESLoanGuids,
  selectProcessingAlpHELOCLoanGuids,
  selectFundedLoanGuids,
  selectFundedCESLoanGuids,
  selectFundedAlpHELOCLoanGuids
} from '../features/loans/loansSlice';
import { selectProcessingPersonalLoanIds, selectFundedPersonalLoanIds, selectPersonalLoans } from '../features/personalLoans/personalLoansSlice';
import { useAppSelector } from '../hooks/hooks';
import { DashboardAlpHELOCLoanCard } from './DashboardAlpHELOCLoanCard';
import { DashboardCESLoanCard } from './DashboardCESLoanCard';
import { DashboardHELOCLoanCard } from './DashboardHELOCLoanCard';
import { DashboardHomeLoanCard } from './DashboardHomeLoanCard';
import { DashboardNoLoansCard } from './DashboardLoanCards';
import { DashboardPersonalLoanCard } from './DashboardPersonalLoanCard';
import { Loan } from '../features/loans/types';

const selectProcessingLoans = createSelector(
  selectProcessingLoanGuids,
  selectLoansByGuidEntity,
  selectProcessingCESLoanGuids,
  selectProcessingAlpHELOCLoanGuids,
  selectProcessingPersonalLoanIds,
  selectProcessingHELOCLoanIds,
  (
    processingHomeLoanGuids,
    homeLoanMap,
    processingCESLoanGuids,
    processingAlpHELOCLoanGuids,
    processingPersonalLoanGuids,
    processingHELOCLoanGuids,
  ) => {
    const now = dayjs();

    const isLoanClickable = (loan: Loan) => {
      const { 'application-status': applicationStatus } = loan;
      if (applicationStatus === 'completed' || applicationStatus === 'not-editable') {
        return true;
      }
      return false;
    };

    const dashboardProcessingHomeLoans = processingHomeLoanGuids?.filter(guid => {
      const loan = homeLoanMap[guid]?.data;
      if (!loan) return false;
      // if click is clickable or if the loan is less than 5 days old
      const { 'started-date': startedDate } = loan;
      return isLoanClickable(loan) || now.diff(dayjs(startedDate)) < 5;
    });

    const dashboardProcessingCESLoans = processingCESLoanGuids?.filter(guid => {
      const loan = homeLoanMap[guid]?.data;
      if (!loan) return false;
      const { 'started-date': startedDate } = loan;
      return isLoanClickable(loan) || now.diff(dayjs(startedDate)) < 5;
    });

    const dashboardProcessingAlpHELOCLoans = processingAlpHELOCLoanGuids?.filter(guid => {
      const loan = homeLoanMap[guid]?.data;
      if (!loan) return false;
      const { 'started-date': startedDate } = loan;
      return isLoanClickable(loan) || now.diff(dayjs(startedDate)) < 5;
    });

    return {
      home: dashboardProcessingHomeLoans,
      ces: dashboardProcessingCESLoans,
      alpHeloc: dashboardProcessingAlpHELOCLoans,
      personal: processingPersonalLoanGuids,
      heloc: processingHELOCLoanGuids,
    };
  }
);

const selectFundedDashboardLoans = createSelector(
  selectFundedLoanGuids,
  selectLoansByGuidEntity,
  selectFundedCESLoanGuids,
  selectFundedAlpHELOCLoanGuids,
  selectFundedPersonalLoanIds,
  selectPersonalLoans,
  selectFundedHELOCLoanIds,
  selectHELOCLoans,
  (
    fundedHomeLoanGuids,
    homeLoansMap,
    fundedCESLoanGuids,
    fundedAlpHELOCLoanGuids,
    fundedPersonalLoanGuids,
    personalLoansMap,
    fundedHELOCLoanGuids,
    helocLoansMap
  ) => {
    const now = dayjs();

    const dashboardFundedHomeLoans = fundedHomeLoanGuids?.filter(guid => {
      const loan = homeLoansMap[guid]?.data;
      const closingDate = loan?.['closing-date'];
      return closingDate && now.diff(dayjs(closingDate), 'days') < 45;
    });

    const dashboardFundedCESLoans = fundedCESLoanGuids?.filter(guid => {
      const loan = homeLoansMap[guid]?.data;
      const closingDate = loan?.['closing-date'];
      return closingDate && now.diff(dayjs(closingDate), 'days') < 45;
    });

    const dashboardFundedAlpHELOCLoans = fundedAlpHELOCLoanGuids?.filter(guid => {
      const loan = homeLoansMap[guid]?.data;
      const closingDate = loan?.['closing-date'];
      return closingDate && now.diff(dayjs(closingDate), 'days') < 45;
    });

    const dashboardFundedPersonalLoans = fundedPersonalLoanGuids?.filter(guid => {
      const personalLoan = personalLoansMap[guid];
      const closingDate = personalLoan.loanDetails.contract?.fundingDate;
      return personalLoan && closingDate && now.diff(dayjs(closingDate), 'days') < 45;
    });

    const dashboardFundedHELOCLoans = fundedHELOCLoanGuids?.filter(guid => {
      const helocLoan = helocLoansMap[guid];
      const closingDate = helocLoan.loanDetails.fundingDate;
      return helocLoan && closingDate && now.diff(dayjs(closingDate), 'days') < 45;
    });

    return {
      home: dashboardFundedHomeLoans,
      ces: dashboardFundedCESLoans,
      alpHeloc: dashboardFundedAlpHELOCLoans,
      personal: dashboardFundedPersonalLoans,
      heloc: dashboardFundedHELOCLoans,
    };
  }
);

const DashboardLoansList = () => {
  const processingLoans = useAppSelector(selectProcessingLoans);
  const fundedLoans = useAppSelector(selectFundedDashboardLoans);

  // Mortgage loans
  const processinghomeLoanIds = processingLoans.home;
  const fundedhomeLoanIds = fundedLoans.home;

  // CES loans
  const processingCESLoanIds = processingLoans.ces;
  const fundedCESLoanIds = fundedLoans.ces;

  // Personal loans
  const processingPersonalLoanIds = processingLoans.personal;
  const fundedPersonalLoanIds = fundedLoans.personal

  // ALP HELOC
  const processingAlpHELOCLoanIds = processingLoans.alpHeloc;
  const fundedAlpHELOCLoanIds = fundedLoans.alpHeloc;

  // HELOC loans
  const processingHELOCLoanIds = processingLoans.heloc;
  const fundedHELOCLoanIds = fundedLoans.heloc;

  const hasProcessingLoans =
    !!processinghomeLoanIds.length ||
    !!processingCESLoanIds.length ||
    !!processingHELOCLoanIds.length ||
    !!processingPersonalLoanIds.length ||
    !!processingAlpHELOCLoanIds.length
    ;

  const hasFundedLoans =
    !!fundedhomeLoanIds?.length ||
    !!fundedCESLoanIds?.length ||
    !!fundedHELOCLoanIds.length ||
    !!fundedPersonalLoanIds.length ||
    !!fundedAlpHELOCLoanIds?.length
    ;

  if (!hasProcessingLoans && !hasFundedLoans) {
    return <DashboardNoLoansCard />;
  }

  return (
    <>
      <ul className='space-y-8'>
        {processinghomeLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardHomeLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingCESLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardCESLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingAlpHELOCLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardAlpHELOCLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingHELOCLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardHELOCLoanCard loanGuid={id} />
            </li>
          );
        })}
        {processingPersonalLoanIds.map(id => {
          return (
            <li key={id}>
              <DashboardPersonalLoanCard loanGuid={id} />
            </li>
          );
        })}
        {fundedhomeLoanIds?.map(id => {
          return (
            <li key={id}>
              <DashboardHomeLoanCard loanGuid={id} />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default DashboardLoansList;
