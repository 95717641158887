import cn from 'classnames';
import { memo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { sendEvent } from '../../features/analytics/sendEvent';
import { unauthenticate } from '../../features/auth/authSlice';
import { isALoanFunded, selectAlpHELOCLoanGuids, selectCESLoanGuids, selectLoan, selectLoanGuids } from '../../features/loans/loansSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import useCobrowse from '../../hooks/useCobrowse';
import useError from '../../hooks/useError';
import { useFeatureEnabled } from '../../hooks/useFeatureEnabled';
import useStartAnApplicationLink from '../../hooks/useStartAnApplicationLink';
import { isDev, isLocalDev } from '../../utils/env';
import { log } from '../../utils/logger';
import { lcfirst } from '../../utils/str';
import { cobrowseSubject } from '../cobrowse/Cobrowse';
import { NAMES, PATHS, TITLES } from '../routes/paths';
import Button from '../ui/button/Button';
import { ButtonProps } from '../ui/button/IButton';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';
import { selectHELOCLoanById, selectHELOCLoanIds } from '../../features/helocLoans/helocLoansSlice';
import { selectPersonalLoanById, selectPersonalLoanIds } from '../../features/personalLoans/personalLoansSlice';
import { useLoanConfig } from '../../hooks/useLoanConfig';
import { Loan } from '../../features/loans/types';
import nextId from '../../utils/nextId';
import { useHELOCLoanConfig } from '../../hooks/useHELOCLoanConfig';
import { usePersonalLoanConfig } from '../../hooks/usePersonalLoanConfig';

type SecondaryNavigationLinkId = 'secondaryNavigationLinks' | 'headerDropdown';

type NavigationButtonProps = ButtonProps & {
  isCurrent: boolean;
};

const NavigationButton = memo(({ buttonType, text, iconName, isCurrent, ...restProps }: NavigationButtonProps) => {
  // aria
  const ariaCurrent: any = {};
  if (isCurrent) ariaCurrent['aria-current'] = 'page';

  return (
    <Button
      buttonType={buttonType}
      text={text}
      {...(iconName && {
        iconName,
        iconPlacement: 'left',
      })}
      {...ariaCurrent}
      {...restProps}
    />
  );
});

export type NavigationLinkProps = ButtonProps & {
  className?: string;
  textClassName?: string;
  id: string;
  text: string;
  disabled?: boolean;
  disableIsCurrent?: boolean;
  showClickIcon?: boolean;
};

export const NavigationLink = ({ className, textClassName, to, disableIsCurrent, showClickIcon = false, ...restProps }: NavigationLinkProps) => {
  const { pathname } = useLocation();
  const anchorRef = useRef(null);

  // Is link current?
  const isCurrent = pathname === to;

  // Is link ancestor current?
  const isLoanCenterAncestor = to === PATHS.loanCenter && pathname.indexOf('/loan/') === 0;
  const isStyleguideAncestor = to === PATHS.styleguide && pathname.indexOf(PATHS.styleguide) === 0;
  const isCurrentAncestor = !isCurrent && (isLoanCenterAncestor || isStyleguideAncestor);
  const showCurrent = !disableIsCurrent && isCurrent;
  const showCurrentAncestor = !disableIsCurrent && isCurrentAncestor;

  const onIconClick = () => {
    const link = anchorRef?.current as HTMLButtonElement | null;
    if (link?.click) {
      link.click();
    }
  };

  return (
    <li
      role='menuitem'
      className={cn(className, 'rounded-xl flex flex-row items-center justify-between', { 'nav-current': showCurrent }, { 'nav-current-ancestor': showCurrentAncestor })}
    >
      <NavigationButton {...restProps} className={textClassName} to={to} isCurrent={showCurrent} anchorRef={anchorRef} />
      {showClickIcon &&
        <Button
          buttonType='icon'
          className='mr-2'
          iconName='chevron-right-small'
          iconSize='1rem'
          text={restProps.text}
          onClick={onIconClick}
        />
      }
    </li>
  );
};

export const useSecondaryNavigationLinks = (id: SecondaryNavigationLinkId) => {
  const dispatch = useAppDispatch();
  const { link: startAnApplicationLink, lo: currentLo } = useStartAnApplicationLink();
  const { enabled: cobrowseEnabled, inSession: cobrowseInSession } = useCobrowse();
  const { showingError } = useError();

  const handleLogout = () => {
    dispatch(unauthenticate());
  };

  const onStartApplicationClick = () => {
    sendEvent(`startAnApplication: ${id}`);
    log({
      level: 'info',
      message: `User clicked \`startAnApplication\` from ${id} lo: ${
        currentLo &&
        JSON.stringify({
          name: currentLo.name,
          email: currentLo.email,
          id: currentLo.id,
          empId: currentLo['emp-id'],
        })
      }`,
    });
  };

  const onStartCobrowse = () => {
    sendEvent(`startCobrowse: ${id}`);
    log({ level: 'info', message: `User clicked \`startCobrowse\` from ${id}` });
    cobrowseSubject.next(true);
  };

  const navLinks: NavigationLinkProps[] = [];
  if (!showingError) {
    navLinks.push(
      {
        buttonType: 'subnav',
        size: 'medium',
        to: PATHS.profile,
        id: NAMES.profile,
        text: TITLES.profile,
        title: `Visit ${lcfirst(TITLES.profile)}`,
        disableIsCurrent: true,
      },
      {
        buttonType: 'subnav',
        size: 'medium',
        href: startAnApplicationLink,
        onClick: onStartApplicationClick,
        target: '_blank',
        rel: 'noopener',
        id: 'start-application',
        text: 'Start an application',
        title: 'Start an application',
      },
    );
  }

  if (cobrowseEnabled && !cobrowseInSession) {
    navLinks.push({
      buttonType: 'subnav',
      size: 'medium',
      onClick: onStartCobrowse,
      id: 'cobrowse',
      text: 'Share screen',
      title: 'Share screen',
    });
  }

  navLinks.push({
    buttonType: 'subnav',
    size: 'medium',
    onClick: handleLogout,
    id: NAMES.logout,
    text: TITLES.logout,
    title: TITLES.logout,
  });

  return navLinks;
};

export const SecondaryNavigationLinks = ({ id, className }: { id?: SecondaryNavigationLinkId; className?: string }) => {
  id = id || 'secondaryNavigationLinks';
  const navLinks = useSecondaryNavigationLinks(id);

  return (
    <ul className={className}>
      {navLinks.map(props => {
        return <NavigationLink key={props.id} {...props}></NavigationLink>;
      })}
    </ul>
  );
};

const NavigationLoanLink = ({ toLink, text }: { toLink?: string, text: string }) => {
  const id = toLink || nextId('nav-loan-link');
  return (
    <NavigationLink
      buttonType='nav'
      to={toLink}
      id={id}
      text={text}
      title={text}
      showClickIcon={true}
      disableIsCurrent={true}
    />
  );
};

const MortgageLoanNavLink = ({ loanGuid, title }: { loanGuid: string, title: string }) => {
  const loan = useAppSelector(state => selectLoan(state, loanGuid));
  const loanConfig = useLoanConfig(loan as Loan);
  if (!loan) return null;
  return (
    <NavigationLoanLink toLink={loanConfig.actionHref} text={`${title} #${loan['loan-number']}`} />
  );
};

const PersonalLoanNavLink = ({ loanGuid }: { loanGuid: string }) => {
  const loan = useAppSelector(state => selectPersonalLoanById(state, loanGuid));
  const loanConfig = usePersonalLoanConfig(loan);
  if (!loan) return null;
  return (
    <NavigationLoanLink toLink={loanConfig?.actionHref} text={`Personal loan #${loan.id}`} />
  );
};

const HELOCLoanNavLink = ({ loanGuid }: { loanGuid: string }) => {
  const loan = useAppSelector(state => selectHELOCLoanById(state, loanGuid));
  const loanConfig = useHELOCLoanConfig(loan);
  if (!loan) return null;
  return (
    <NavigationLoanLink toLink={loanConfig?.actionHref} text={`HELOC loan #${loan.id}`} />
  );
};

const LoansListNavigation = () => {
  // mortgage loans
  const homeLoanGuids = useAppSelector(selectLoanGuids);
  // CES loans
  const cesLoanGuids = useAppSelector(selectCESLoanGuids);
  // ALP HeLOC
  const alpHELOCLoanGuids = useAppSelector(selectAlpHELOCLoanGuids);
  // personal loans
  const personalLoanGuids = useAppSelector(selectPersonalLoanIds);
  // HELOC loans
  const helocLoanIds = useAppSelector(selectHELOCLoanIds);

  let loanCount = 0;
  const MAX_DISPLAY_LOANS_NUM = 5;
  const loanNavigationList: React.ReactNode[] = [];

  // mortgage loans
  for (let i = 0; loanCount < MAX_DISPLAY_LOANS_NUM && i < homeLoanGuids.length; i++) {
    loanNavigationList.push(<MortgageLoanNavLink key={loanCount} loanGuid={homeLoanGuids[i]} title='Loan' />);
    loanCount++;
  }

  // CES loans
  for (let i = 0; loanCount < MAX_DISPLAY_LOANS_NUM && i < cesLoanGuids.length; i++) {
    loanNavigationList.push(<MortgageLoanNavLink key={loanCount} loanGuid={cesLoanGuids[i]} title='CES loan' />);
    loanCount++;
  }

  // ALP HELOC loans
  for (let i = 0; loanCount < MAX_DISPLAY_LOANS_NUM && i < alpHELOCLoanGuids.length; i++) {
    loanNavigationList.push(<MortgageLoanNavLink key={loanCount} loanGuid={alpHELOCLoanGuids[i]} title='HELOC loan' />);
    loanCount++;
  }

  // personal loans
  for (let i = 0; loanCount < MAX_DISPLAY_LOANS_NUM && i < personalLoanGuids.length; i++) {
    loanNavigationList.push(<PersonalLoanNavLink key={loanCount} loanGuid={personalLoanGuids[i]} />);
    loanCount++;
  }

  // HELOC loans
  for (let i = 0; loanCount < MAX_DISPLAY_LOANS_NUM && i < helocLoanIds.length; i++) {
    loanNavigationList.push(<HELOCLoanNavLink key={loanCount} loanGuid={helocLoanIds[i]} />);
    loanCount++;
  }

  return (
    <>
      {loanNavigationList.map(loanNav => {
        return loanNav;
      })}
    </>
  );
};

export const MainNavigationLinks = ({ className }: { className?: string }) => {
  const config = getConfigFromStore();
  const canShowHomeValue = useAppSelector(isALoanFunded);
  const isDashboardEnabled = useFeatureEnabled('dashboard');
  const isHomeValueEnabled =
    (useFeatureEnabled('home-value') && canShowHomeValue) || (isLocalDev && process.env.REACT_APP_ENABLE_HOME_VAL);
  const isHomeSearchEnabled = useFeatureEnabled('home-search') && config?.links['home-search'];
  const isInsuranceLandingEnabled = useFeatureEnabled('insurance-landing');
  const isStyleguideEnabled = isLocalDev || isDev;
  const { link: startAnApplicationLink } = useStartAnApplicationLink();

  return (
    <ul className={className}>
      {isDashboardEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.dashboard}
          id={NAMES.dashboard}
          text={TITLES.dashboard}
          title={`Visit ${lcfirst(TITLES.dashboard)}`}
          disableIsCurrent={true}
          textClassName='font-bold'
        />
      )}
      <NavigationLink
        buttonType='nav'
        to={PATHS.loanCenter}
        id={NAMES.loanCenter}
        text={TITLES.loanCenter}
        title={`Visit ${lcfirst(TITLES.loanCenter)}`}
        disableIsCurrent={true}
        textClassName='font-bold'
      />
      {/* show up to 5 nav to loans */}
      <LoansListNavigation />
      {/* link to DMX application */}
      <NavigationLink
        buttonType='nav'
        href={startAnApplicationLink}
        id={startAnApplicationLink}
        text='New loan'
        title='New loan'
        target='_blank'
        rel='noopener'
        disableIsCurrent={true}
        textClassName='font-bold text-tertiaryBtn-text pb-3'
      />
      {isHomeSearchEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.homeSearch}
          id={NAMES.homeSearch}
          text={TITLES.homeSearch}
          title={`Visit ${lcfirst(TITLES.homeSearch)}`}
          disableIsCurrent={true}
          textClassName='font-bold py-3'
        />
      )}
      {isInsuranceLandingEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.insurance}
          id={NAMES.insurance}
          text={TITLES.insurance}
          title={`Visit ${lcfirst(TITLES.insurance)}`}
          disableIsCurrent={true}
          textClassName='font-bold py-3'
        />
      )}
      {isHomeValueEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.homeValue}
          id={NAMES.homeValue}
          text={TITLES.homeValue}
          title={`Visit ${lcfirst(TITLES.homeValue)}`}
          disableIsCurrent={true}
          textClassName='font-bold py-3'
        />
      )}
      {isStyleguideEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.styleguide}
          id={NAMES.styleguide}
          text={TITLES.styleguide}
          title={`Visit ${lcfirst(TITLES.styleguide)}`}
          disableIsCurrent={true}
          textClassName='font-bold py-3'
        />
      )}
    </ul>
  );
};
