import { useRef } from 'react';
import { DashboardMilestoneBadge } from '../components/loan/milestone/MilestoneComposite';
import Avatar from '../components/ui/avatar/Avatar';
import ApplyLoanButton from '../components/ui/button/ApplyLoanButton';
import Button from '../components/ui/button/Button';
import Card from '../components/ui/card/Card';
import { sendEvent } from '../features/analytics/hotjar';
import { LoanOfficer, LoanTeam } from '../features/loans/types';
import { formatAddressObject } from '../utils/addressFormatter';
import { usdAmount } from '../utils/amount';
import { formatDate, FORMAT_SINGLE_DIGIT_SHORT_YEAR } from '../utils/date';
import { log } from '../utils/logger';
import cn from 'classnames';
import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import IAddress from '../interfaces/IAddress';

export const DashboardNoLoansCard = () => {
  const onApplyClick = () => {
    sendEvent('startAnApplication: dashboard');
    log({ level: 'info', message: 'startAnApplication: dashboard' });
  };

  return (
    <Card className='bg-white' dropShadow={true}>
      <div className='flex flex-col lg:flex-row items-baseline lg:items-center'>
        <div className='flex flex-col flex-grow'>
          <p className='text-xl font-bold'>No loans in progress</p>
          <p className='mt-2'>You currently have no loans in progress. You can begin the process by applying for a loan.</p>
        </div>
        <ApplyLoanButton className='mt-4 lg:mt-0 w-full lg:w-fit-content whitespace-nowrap h-fit-content' onClick={onApplyClick} buttonType='primary' />
      </div>
    </Card>
  );
};

export const DashboardLoanCard = (
  {
    loanConfig,
    team,
    loanAmount,
    address,
    startedDate,
    loanNumber,
  }:
  {
    loanConfig: LoanConfig,
    team?: LoanTeam,
    loanAmount: number,
    address?: IAddress,
    startedDate?: string,
    loanNumber: string,
  }
) => {
  const anchorRef = useRef(null);

  const onCardClick = () => {
    const link = anchorRef?.current as HTMLButtonElement | null;
    if (link?.click) {
      link.click();
    }
  };

  const LoanOfficerRow = ({ loanOfficer, className }: { loanOfficer: LoanOfficer, className?: string }) => {
    return (
      <div className={cn(className, 'flex flex-row items-center')}>
        <Avatar
          size='small'
          photo={loanOfficer['photo-url']}
          alt={`Photo of ${loanOfficer.name}`}
          data-qa='person-photo'
        />
        <p className='ml-2'>Working with {loanOfficer.name}</p>
      </div>
    );
  };

  return (
    <Card
      onClick={onCardClick}
      anchorRef={anchorRef}
      dropShadow={true}
      className='bg-white'
    >
      <div className='flex flex-row'>
        <div className='flex flex-col flex-grow'>
          {loanConfig.milestoneText && <DashboardMilestoneBadge className='mb-2' text={loanConfig.milestoneText} />}
          <p className='text-xl font-bold'>{usdAmount(loanAmount, false)}</p>
          {address && <p className='mb-2'>{formatAddressObject(address)}</p>}
          {startedDate && <p className='text-xs'>Application date {formatDate(startedDate, FORMAT_SINGLE_DIGIT_SHORT_YEAR)}</p>}
          <p className='text-xs'>{loanConfig.loanTypeText} {loanNumber}</p>
          {team?.lo && <LoanOfficerRow className='mt-2' loanOfficer={team?.lo} />}
        </div>
        {loanConfig.actionHref && loanConfig.actionLabel && (
          <div
            className={`flex justify-end h-full ${loanConfig.showTracker ? 'col-span-2 md:col-span-1' : 'col-span-1'}`}
            style={{ height: 'min-content', position: 'relative', bottom: '8px' }}
          >
            <Button
              anchorRef={anchorRef}
              buttonType='icon'
              text={loanConfig.actionLabel}
              title={loanConfig.actionLabel}
              iconSize='1.25rem'
              iconName='chevron-right-large'
              iconHoverEffect={true}
              to={loanConfig.actionHref}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
