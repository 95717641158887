import cn from 'classnames';
import { useIsAuth } from '../../features/auth/authSlice';
import { useUser } from '../../features/user/userSlice';
import { useMultilingual } from '../../hooks/useMultilingual';
import { usePathBreadcrumbs } from '../../hooks/usePathBreadcrumbs';
import FlyoutComponent from '../flyout/FlyoutComponent';
import LanguageButton from '../multilingual/languageButton/LanguageButton';
import TopMenu from '../navigation/TopMenu';
import Breadcrumbs from '../ui/breadcrumbs/Breadcrumbs';
import { ImagePreloader } from '../ui/image/ImagePreloader';
import HeaderDropdown from './HeaderDropdown';
import HeaderLogo from './HeaderLogo';
import myAccountLogo from '../../images/myaccount-logo.svg';
import themeConfig from '../../features/tenant/tenant';
import useError from '../../hooks/useError';
import useEmbed from '../../hooks/useEmbed';

const Header = () => {
  const { mobileLogo } = themeConfig.content.header;
  const user = useUser();
  const shouldShowLanguageButton = useMultilingual();
  const isAuthenticated = useIsAuth();
  const { showingError, isUnsupportedBrowser } = useError();

  const embed = useEmbed();
  let breadcrumbs = usePathBreadcrumbs();
  const showBreadcrumbs = !showingError && !isUnsupportedBrowser;
  breadcrumbs = showBreadcrumbs ? breadcrumbs : breadcrumbs.splice(0, 1);

  return (
    <header
      className={cn('header lg:justify-between', {
        'justify-start': isAuthenticated,
        'justify-between': !isAuthenticated,
      })}
    >
      {(showingError || (!showingError && !isUnsupportedBrowser && isAuthenticated && !embed)) && (
        <div className={cn('h-10', { 'lg:hidden': showingError })} style={{ marginRight: '6px' }}>
          {!isUnsupportedBrowser && isAuthenticated && (
            <>
              {/* The following images are used within the flyout component's child component */}
              <ImagePreloader>
                <img src={myAccountLogo} alt='myAccount logo' />
                {mobileLogo && <img src='/images/brand-logo-mobile.svg' alt='brand logo for mobile' />}
              </ImagePreloader>
              <FlyoutComponent
                contentLabel='Navigation Menu'
                flyoutComponent={closeFn => {
                  return <TopMenu closeFn={closeFn} />;
                }}
                orientation='left'
              >
                <div className='hamburger'>
                  <div className='hamburger-inner'></div>
                </div>
              </FlyoutComponent>
            </>
          )}
        </div>
      )}
      <div className='flex'>
        <HeaderLogo className='lg:mr-8' />
        {!embed && (
          <div className='hidden lg:flex items-center'>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
      </div>
      {!isAuthenticated && shouldShowLanguageButton && <LanguageButton />}
      {isAuthenticated && (
        <div className='header-user ml-auto'>
          {shouldShowLanguageButton && (
            <div className='lg:mr-3'>
              <LanguageButton />
            </div>
          )}
          {!embed && (
            <div className='hidden lg:inline'>
              <HeaderDropdown user={user} />
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
