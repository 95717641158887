import axios from 'axios';
import { useEffect, useState } from 'react';
import api from '../../../features/api';
import { Task } from '../../../features/tasks/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import { log } from '../../../utils/logger';
import { serializeError } from '../../../utils/serializeError';
import submitForm from '../../../utils/submitForm';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import TaskCompletedDocuments from '../task/TaskCompletedDocuments';
import Alert from '../../ui/alert/Alert';
import { sendTaskUpdateEvent } from '../../../features/analytics/sendTaskUpdateEvent';

export const DOCUTECH_TYPES = ['document_signing'] as const;

const DocutechTaskBody = ({ loanGuid, task }: { loanGuid: string, task: Task<'document_signing'> }) => {
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const { 'task-id': taskId, 'task-completed': taskCompleted, documents: taskDocuments, 'task-type': taskType } = task;
  const docNotSigned = !taskCompleted;
  const docCompleted = !!taskCompleted && taskDocuments && taskDocuments.length > 0;
  const docPending = !!taskCompleted && !docCompleted;
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleDocutechRedirect = async (openWindow: boolean, action: 'sign' | 'view') => {
    let newWindow = undefined;
    try {
      sendTaskUpdateEvent({ taskId, taskType });
      log({ loanGuid, taskId, level: 'info', message: `Docutech: redirect ${action}` });
      setLoading(true);
      setError(undefined);
      if (openWindow) {
        newWindow = window.open();
      }
      const resp = await api.getSignSsoLink(loanGuid, taskId, `${window.location.origin}/loan/${loanGuid}/tasks`);
      if (resp?.data && resp.data.actionUrl && resp.data.accessToken) {
        const { actionUrl, accessToken } = resp.data;
        submitForm({ action: actionUrl, fields: { access_token: accessToken }, newWindow: openWindow ? newWindow: undefined });
        log({
          loanGuid, taskId,
          level: 'info',
          // grab the first 5 letters of the token if available
          message: `Docutech: submit form ${actionUrl} token ${accessToken.substr(0, (accessToken.length > 10 ? 5 : accessToken.length / 2))}...`,
        });
        setLoading(false);
      } else {
        handleError(`response not valid ${JSON.stringify(resp)}`);
      }
    } catch (error) {
      newWindow?.close();
      if (axios.isAxiosError(error)) {
        handleError(`Axios error ${error.response?.status}`);
      } else {
        handleError(serializeError(error));
      }
    }
  };

  const handleError = (errorTrace: string) => {
    setLoading(false);
    setError('Cannot open document for signing. Please try again later.');
    log({ loanGuid, taskId, level: 'error', message: `Docutech: redirect error ${errorTrace}` });
  };

  const DocumentNotSigned = () => {
    return (
      <div className='flex flex-col justify-center'>
        <FormButtonComponent
          buttonContainerClassName='mt-0 w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          error={error}
          loading={loading}
          onClick={() => handleDocutechRedirect(true, 'sign')}
        >
          Sign documents
        </FormButtonComponent>
      </div>
    );
  };

  const DocumentPending = () => {
    return (
      <div className='flex flex-col justify-center text-sm lg:text-base'>
        <Alert
          type='info'
          className='mb-6'
          description='You have already completed this task, you may be waiting on additional parties to sign.'
          showClose={false}
        />
        <FormButtonComponent
          buttonContainerClassName='mt-0 w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          error={error}
          loading={loading}
          onClick={() => handleDocutechRedirect(true, 'view')}
        >
          View documents
        </FormButtonComponent>
      </div>
    );
  };

  return (
    <>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      {docNotSigned && <DocumentNotSigned />}
      {docPending && <DocumentPending />}
      {docCompleted && <TaskCompletedDocuments loanGuid={loanGuid} task={task} showTaskDescription={false} />}
    </>
  );
};

export default DocutechTaskBody;
