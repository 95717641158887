import { useLoan } from '../features/loans/hooks';
import { Loan } from '../features/loans/types';
import { useLoanConfig } from '../hooks/useLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';

export const DashboardHomeLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const loanConfig = useLoanConfig(loan);
  const team = loan?.team;

  return (
    <DashboardLoanCard
      loanConfig={loanConfig}
      team={team}
      loanAmount={loan['base-loan-amount']}
      address={loan.property}
      startedDate={loan['started-date']}
      loanNumber={loan['loan-number']}
    />
  );
};

export const DashboardHomeLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  if (!data) return null;
  return (
    <DashboardHomeLoanCardPresentational loan={data} />
  );
};
