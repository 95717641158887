import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import { linkTo, PATHS } from '../components/routes/paths';
import { isFundedLoan } from '../features/loans/helpers';
import { useLoan } from '../features/loans/hooks';
import { Loan } from '../features/loans/types';
import { DashboardLoanCard } from './DashboardLoanCards';

export const DashboardCESLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const loanConfig: LoanConfig = {
    loanTypeText: 'Home Equity',
    showTracker: !isFundedLoan(loan),
    trackerValue: 50,
    actionHref: isFundedLoan(loan) ?
      linkTo(PATHS.homeLoanServicing, { guid: loanGuid }) :
      linkTo(PATHS.homeLoanOverview, { guid: loanGuid }),
    actionLabel: 'View loan',
  };

  return (
    <DashboardLoanCard
      loanAmount={loan['base-loan-amount']}
      loanConfig={loanConfig}
      startedDate={loan['started-date']}
      loanNumber={loan['loan-number']}
      address={loan.property}
    />
  );
};

export const DashboardCESLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  if (!data) return null;
  return (
    <DashboardCESLoanCardPresentational loan={data} />
  );
};
