import { getHELOCProperty } from '../components/loan/loanCard/HELOCLoanCard';
import { getDisplayedLoanNumber, selectHELOCLoanById } from '../features/helocLoans/helocLoansSlice';
import { iHELOCLoan } from '../features/helocLoans/types';
import { useAppSelector } from '../hooks/hooks';
import { useHELOCLoanConfig } from '../hooks/useHELOCLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';

export const DashboardHELOCLoanCardPresentational = ({ loan }: { loan: iHELOCLoan }) => {
  const loanConfig = useHELOCLoanConfig(loan);
  const loanNumber = getDisplayedLoanNumber(loan);
  if (!loanConfig) return null;
  return (
    <DashboardLoanCard
      loanAmount={loan.loanDetails.initialDrawAmount}
      loanConfig={loanConfig}
      startedDate={loan.loanDetails.fundingDate}
      loanNumber={loanNumber}
      address={getHELOCProperty(loan.propertyAddress)}
    />
  );
};

export const DashboardHELOCLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const loan = useAppSelector(state => selectHELOCLoanById(state, loanGuid));
  if (!loan) return null;
  return (
    <DashboardHELOCLoanCardPresentational loan={loan} />
  );
};
