import AnimateHeight from 'react-animate-height';
import Button from '../components/ui/button/Button';
import { selectFundedLoanGuids } from '../features/loans/loansSlice';
import { useFetchRateAlerts } from '../features/rateAlert/useFetchRateAlert';
import { useAppSelector } from '../hooks/hooks';
import { usdAmount } from '../utils/amount';
import { formatPercent } from '../utils/percent';
import { useState } from 'react';
import cn from 'classnames';
import { rateAlertEdit } from '../utils/grLinks';
import ApplyLoanButton from '../components/ui/button/ApplyLoanButton';
import { log } from '../utils/logger';
import { RateAlert } from '../features/rateAlert/types';
import rateAlertImage from '../images/rate-alert-logo.svg';

const DashboardRateAlert = ({ className }: { className?: string }) => {
  const fundedhomeLoanIds = useAppSelector(selectFundedLoanGuids);
  const rateAlerts = useFetchRateAlerts(fundedhomeLoanIds);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const getFirstRateAlert = (rateAlerts: (RateAlert | undefined)[]) => {
    if (rateAlerts && Array.isArray(rateAlerts) && rateAlerts.length > 0) {
      for (const rateAlert of rateAlerts) {
        if (rateAlert && rateAlert.loanInfo?.interestRate && rateAlert.monitorInfo?.estimatedYearlySavingsAmount && rateAlert.data?.targetRate) {
          return rateAlert;
        }
      }
    }
    return undefined;
  };

  const rateAlert = getFirstRateAlert(rateAlerts);

  if (!rateAlert) {
    return null;
  }

  // has a rate alert - should only have one
  const monthlySavings = (rateAlert?.monitorInfo?.estimatedYearlySavingsAmount || 0) / 12;

  const onApplyLoanClick = () => {
    log({ message: `Apply new loan from dashboard` });
  };

  const onEditRateAlert = (loanGuid: string, invitationNumber: string) => {
    log({ loanGuid, message: `Edit rate alert invitation ${invitationNumber}` });
  };

  return (
    <>
      <div className={cn(className, 'flex flex-col border border-inactive-25 rounded-xl p-4 md:p-6')}>
        <div className='flex flex-row items-center justify-between mb-2'>
          <img className='h-4 flex self-center' src={rateAlertImage} alt='Rate Alert' />
          <Button
            href={rateAlertEdit(rateAlert.invitationNumber)}
            target='_blank'
            buttonType='icon'
            iconName='chevron-right-large'
            iconSize='1.25rem'
            text='My rate alert'
            iconHoverEffect={true}
            onClick={() => onEditRateAlert(rateAlert.loanInfo.loanGuid, rateAlert.invitationNumber)}
          />
        </div>
        <div className='grid grid-cols-2 lg:grid-cols-4 bg-tab-active rounded-xl p-4 md:p-6'>
          <div className='flex flex-col'>
            <p className='text-sm'>Current interest rate</p>
            <p className='text-lg font-bold'>{formatPercent(rateAlert.loanInfo?.interestRate, 2)}</p>
          </div>
          <div className='flex flex-col'>
            <p className='text-sm'>Target rate</p>
            <p className='text-lg font-bold'>{formatPercent(rateAlert.data?.targetRate, 2)}</p>
          </div>
          <div className='flex flex-col'>
            <p className='text-sm'>Yearly savings</p>
            <p className='text-lg font-bold'>{usdAmount(rateAlert.monitorInfo?.estimatedYearlySavingsAmount, false)}</p>
          </div>
          <div className='flex flex-col'>
            <p className='text-sm'>Monthly savings</p>
            <p className='text-lg font-bold'>{usdAmount(monthlySavings, false)}</p>
          </div>
        </div>
        <div className='mt-2'>
          <Button
            buttonType={'tertiary'}
            size={'small'}
            onClick={() => setShowDisclaimer(!showDisclaimer)}
            iconName={showDisclaimer ? 'chevron-up-small' : 'chevron-down-small'}
            iconPlacement='right'
            iconSize='10px'
            text='Disclaimers'
          />
          <AnimateHeight height={showDisclaimer ? 'auto' : 0} duration={250}>
            <p className='mt-2 text-xs' style={{ fontStretch: 'condensed' }}>
              {`Based on your ${usdAmount(
                rateAlert.loanInfo?.monthlyPayment,
              )} monthly principal and interest payment and ${formatPercent(
                rateAlert.loanInfo?.interestRate,
              )} interest rate. Savings, if any, vary based on the consumer's credit profile, interest rate availability, and other factors.`}
            </p>
          </AnimateHeight>
        </div>
      </div>
      <ApplyLoanButton className='mt-4' onClick={onApplyLoanClick} text='Start a new loan' />
    </>
  );
};

export default DashboardRateAlert;
