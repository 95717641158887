import { useLoan } from '../features/loans/hooks';
import { Loan } from '../features/loans/types';
import { useAlpHELOCLoanConfig } from '../hooks/useAlpHELOCLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';

export const DashboardAlpHELOCLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-number': loanNumber, property, 'base-loan-amount': loanAmount, 'started-date': startedDate } = loan;
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <DashboardLoanCard
      loanAmount={loanAmount}
      loanConfig={loanConfig}
      startedDate={startedDate}
      loanNumber={loanNumber}
      address={property}
    />
  );
};

export const DashboardAlpHELOCLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  if (!data) return null;
  return (
    <DashboardAlpHELOCLoanCardPresentational loan={data} />
  );
};
