import { getDisplayedLoanNumber, selectPersonalLoanById } from '../features/personalLoans/personalLoansSlice';
import { iPersonalLoan } from '../features/personalLoans/types';
import { useAppSelector } from '../hooks/hooks';
import { usePersonalLoanConfig } from '../hooks/usePersonalLoanConfig';
import { DashboardLoanCard } from './DashboardLoanCards';

export const DashboardPersonalLoanCardPresentational = ({ loan }: { loan: iPersonalLoan }) => {
  const loanConfig = usePersonalLoanConfig(loan);
  const loanNumber = getDisplayedLoanNumber(loan);
  if (!loanConfig) return null;
  return (
    <DashboardLoanCard
      loanAmount={loan.loanDetails.amount}
      loanConfig={loanConfig}
      loanNumber={loanNumber}
      startedDate={loan.loanDetails.contract?.fundingDate}
    />
  );
};

export const DashboardPersonalLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const loan = useAppSelector(state => selectPersonalLoanById(state, loanGuid));
  if (!loan) return null;
  return (
    <DashboardPersonalLoanCardPresentational loan={loan} />
  );
};
